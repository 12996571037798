<template>
  <div id="blogs">
    <HeadSections
      class="blog-img"
      :TitlePage="this.$i18n.locale == 'ar' ? 'تعرف على اخر الاخبار !':'Find out the latest news !'"
      :TitleSec="this.$i18n.locale == 'ar' ? 'كن على معرفة بكل ما هو جديد.' : 'Stay up to date with everything new.'"
      :PathPageFrom="$t('Home')"
      :PathPageTo="$t('Blogs')"
      :pathFrom="`/`"
    />
    <div class="container" style=" overflow: hidden;margin-top: -70px;">
      <IndexBlogs/>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'blogs',
  components: {
    HeadSections: defineAsyncComponent(() => import('@/components/Global/HeadSections.vue')),
    IndexBlogs: defineAsyncComponent(() => import('@/components/Blogs/Index.vue')),
  },
}
</script>
